<template>
    <div>
        <input type="hidden" id="copyinput">
        <el-card class="global-box-card global-no-box-shadow">
            <template #header>
                <el-form ref="formRef" :inline="true" :model="search_config.form" label-width="90px">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="门店名称" prop="name">
                                <el-input v-model="search_config.form.name" placeholder="门店名称" @keyup.enter="searchHandle"></el-input>
                            </el-form-item>
                            <el-form-item label="门店前台账号" prop="contact_mobile">
                                <el-input v-model="search_config.form.contact_mobile" placeholder="门店前台账号" @keyup.enter="searchHandle"></el-input>
                            </el-form-item>
                            <el-form-item label="所属工厂" prop="factory_id">
                                <el-select v-model="search_config.form.factory_id" placeholder="选择所属工厂">
                                  <el-option
                                    v-for="item in search_config.factory_list"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                  </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="门店状态" prop="status">
                                <el-select v-model="search_config.form.status" placeholder="选择状态">
                                  <el-option
                                    v-for="item in search_config.status_options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                  </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-button class="global-ml-10" @click="resetHandle">重置</el-button>
                                <el-button class="global-ml-10" type="primary" @click="searchHandle">查询</el-button>
                                <el-button class="global-ml-10" type="primary" @click="openCreateConfig">新增门店</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </template>
            <div>
                <table-component :table="table">
                    <template #default="data">
                        <div v-if="data.item.prop == 'status'">
                          <el-switch v-model="data.scope.row[data.item.prop]" @change="statusChange(data.scope.row)"></el-switch>
                        </div>
                        <!-- <div v-else-if="data.item.prop == 'gzh_openid'">
                          <p @click="copyText(data.scope.row)">{{data.scope.row[data.item.prop]}}</p>
                        </div> -->
                        <div v-else-if="data.item.prop == 'avatarUrl'">
                          <el-image v-if="data.scope.row[data.item.prop]" style="width: 40px; height: 40px" :src="data.scope.row[data.item.prop]" fit="cover" />
                          <span v-else>无</span>
                        </div>
                        <div v-else-if="data.item.prop == 'focus'">
                          <el-tag v-if="data.scope.row[data.item.prop] == 2">已关注</el-tag>
                          <el-tag v-else type="danger">未关注</el-tag>
                        </div>
                        <div v-else-if="data.item.prop == 'control'">
                          <el-button @click="editHandle(data.scope.row)">编辑</el-button>
                        </div>
                        <div v-else>{{data.scope.row[data.item.prop]}}</div>
                    </template>
                </table-component>
                <div class="pagination-box">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pagination.page"
                        :page-sizes="pagination.page_sizes"
                        :page-size="pagination.page_size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pagination.total">
                    </el-pagination>
                </div>
            </div>
        </el-card>

        <!-- 新增门店 start -->
        <el-dialog
            :title="create_config.title"
            v-model="create_config.is_show"
            width="600px"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div>
                <el-form ref="payConfigFormRef" :model="create_config.form" :rules="create_config.rules" label-width="100px">
                    <el-form-item label="门店名称" prop="name">
                        <el-input v-model="create_config.form.name" placeholder="门店名称"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人姓名" prop="contact">
                        <el-input v-model="create_config.form.contact" placeholder="请输入负责人姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="登录手机号" prop="contact_mobile">
                        <el-input v-model="create_config.form.contact_mobile" placeholder="请输入门店手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱号" prop="email">
                        <el-input v-model="create_config.form.email" placeholder="请输入门店邮箱号"></el-input>
                    </el-form-item>
                    <el-form-item label="登录密码" prop="password">
                        <el-input v-model="create_config.form.password" type="password" placeholder="请输入登录密码"></el-input>
                    </el-form-item>
                    <el-form-item label="门店基本信息" prop="address">
                        <el-input type="textarea" :rows="4" v-model="create_config.form.address" placeholder="请输入门店基本信息"></el-input>
                    </el-form-item>
                    <el-form-item label="指派工厂" prop="factory_id">
                        <el-select v-model="create_config.form.factory_id" placeholder="选择所属工厂">
                          <el-option
                            v-for="item in search_config.factory_list"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="门店状态" prop="status">
                        <el-switch v-model="search_config.form.status"></el-switch>
                    </el-form-item> -->
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="confirmHandle">确定</el-button>
                    <el-button @click="closeCreateConfig">取消</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 新增门店 end -->

    </div>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import {platformApi} from '@/api'
import { ElMessage,ElLoading } from 'element-plus';
import tableComponent from '@/components/table/index.vue'
export default defineComponent({
  name: "order-manage-page",
  components:{
    tableComponent
  },
  setup(props) {
    let payConfigFormRef = ref(null)
    let formRef = ref(null)
    let pageData = reactive({
      create_config:{
        is_show:false,
        title:'新增门店',
        form:{
          name:'',
          contact:'',
          contact_mobile:'',
          password:'',
          address:'',
          factory_id:null,
          email:''
        },
        rules: {
          name: [
            { required: true, message: '请输入门店名称', trigger: 'blur' },
          ],
          contact: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          contact_mobile: [
            { required: true, message: '请输入门店手机号码', trigger: 'blur' },
          ],
          email: [
            { required: true, message: '请输入门店邮箱号', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入门店密码', trigger: 'blur' },
          ],
          address: [
            { required: true, message: '请输入基本信息', trigger: 'blur' },
          ],
          factory_id: [
            { required: true, message: '请选择指派工厂', trigger: 'change' },
          ],
        }
      },
      search_config:{
        form:{
          name:'',
          contact_mobile:'',
          status:'0',
          factory_id:null
        },
        status_options:[
          {
            label:'全部',
            value:'0'
          },
          {
            label:'启用',
            value:'1'
          },
          {
            label:'禁用',
            value:'2'
          }
        ]
      },
      table:{
        column_key:[
            {
                prop:'name',
                label:'门店名称'
            },
            {
                prop:'address',
                label:'门店基本信息'
            },
            {
                prop:'nickName',
                label:'微信昵称'
            },
            {
                prop:'avatarUrl',
                label:'微信头像'
            },
            {
                prop:'contact_mobile',
                label:'手机号码（登录账号）'
            },
            {
                prop:'focus',
                label:'服务号'
            },
            {
                prop:'gzh_openid',
                label:'服务号openid'
            },
            {
                prop:'factory_name',
                label:'所属工厂'
            },
            {
                prop:'status',
                label:'门店状态'
            },
            {
                prop:'email',
                label:'邮箱号'
            },
            {
                prop:'control',
                label:'操作'
            },
        ],
        list:[]
      },
      pagination:{
        page:1,
        page_size:10,
        total:0,
        page_sizes:[10,50,100,200,500]
      },
    })
    onMounted(() => {
      getFactoryList()
      getList()
    });

    function getList(){
      platformApi.shopsList({
        ...pageData.search_config.form,
        page:pageData.pagination.page,
        pageSize:pageData.pagination.page_size,
      }).then(res=>{
        let list = res.data.list
        for(let i in list){
          list[i].status = list[i].status == 1 ? true : false
          // 转换整数
          if(list[i].factory_id){
            list[i].factory_id = parseInt(list[i].factory_id)
          }
        }
        pageData.table.list = list
        pageData.pagination.total = res.data.total
      })
    }

    // function copyText(item){
    //   if(item.gzh_openid){
    //     var copyinput = document.getElementById("copyinput");
    //     copyinput.value = item.gzh_openid; // 修改文本框的内容
    //     copyinput.select(); // 选中文本
    //     document.execCommand("copy"); // 执行浏览器复制命令
    //     ElMessage.success('复制成功')
    //     copyinput.value = ''
    //   }
    // }

    function getFactoryList(){
      platformApi.factoryList({
        page:1,
        pageSize:50,
      }).then(res=>{
        let list = res.data.list
        pageData.search_config.factory_list = list
      })
    }

    function editHandle(item){
      let current_item = JSON.parse(JSON.stringify(item))
      current_item.factory_id = current_item.factory_id > 0 ? current_item.factory_id : null
      openCreateConfig()
      nextTick(()=>{
        pageData.create_config.form = current_item
      })
    }

    function searchHandle(){
        getList()
    }

    function resetHandle(){
        formRef.value.resetFields()
        getList()
    }

    function handleSizeChange(page_size){
        pageData.pagination.page_size = page_size
        getList()
    }
    function handleCurrentChange(page){
        pageData.pagination.page = page
        getList()
    }

    function openCreateConfig(){
      pageData.create_config.is_show = true
    }

    function closeCreateConfig(){
      payConfigFormRef.value.resetFields()
      pageData.create_config.form.id = null
      pageData.create_config.is_show = false
    }

    function confirmHandle(){
      payConfigFormRef.value.validate((valid)=>{
        if(valid){
          let form = JSON.parse(JSON.stringify(pageData.create_config.form))
          delete form.status
          if(form.id){
            platformApi.shopsEdit(form).then(res=>{
              closeCreateConfig()
              getList()
            })
          }else{
            platformApi.shopsCreate(form).then(res=>{
              closeCreateConfig()
              getList()
            })
          }
        }
      })
    }

    function statusChange(item){
      platformApi.shopsChangStatus({
        id:item.id,
        status:item.status ? 1 : 2
      }).catch(err=>{
        item.status = !item.status
      })
    }

    return {
      ...toRefs(pageData),
      payConfigFormRef,
      formRef,
      searchHandle,
      resetHandle,
      handleSizeChange,
      handleCurrentChange,
      openCreateConfig,
      closeCreateConfig,
      confirmHandle,
      editHandle,
      statusChange,
      // copyText
    };
  },
});
</script>
<style lang="scss" scoped>
.hint-text{
  color: red;
  margin-left: 10px;
}
</style>